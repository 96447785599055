<template>
  <el-dialog
    append-to-body
    width="880px"
    top="3vh"
    title="批量添加"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    >
    <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
      <el-form-item prop="bind_id">
        <el-select v-model="table.params.bind_id" filterable id="bind_id" placeholder="请选择供应商/采购员" clearable @change="onSearch">
          <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="good_name">
        <el-input v-model="table.params.good_name" placeholder="请输入商品名称" @keydown.enter.native="onSearch" style="width:150px;"></el-input>
      </el-form-item>
      <el-form-item prop="good_name">
        <el-select v-model="table.params.status" placeholder="上下架状态" style="width:120px;" @change="onSearch">
          <el-option label="上架" :value="1"></el-option>
          <el-option label="下架" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
        <el-button icon="el-icon-refresh-right" @click="resetSel">重置</el-button>
      </el-form-item>
      <div style="margin-top:5px;" >
        <span v-for="(v,index) in orderCateOpt" :key="v.id" :class="index == cateIndex ? 'cate-item active' : 'cate-item'" @click="handleCate(index,v)">
          {{v.name}}
        </span>
      </div>
      <div style="margin-top:5px;" v-if="cateIndex != 0">
        <span v-for="(j,index2) in orderCateOpt[cateIndex].child" :key="j.id" :class="index2 == cateIndex2 ? 'cate-item active' : 'cate-item'" @click="handleCate2(index2,j)" >
          {{j.name}}
        </span>
      </div>
    </el-form>
    
    <el-table 
      ref="multipleTable" 
      :data="table.data" 
      border 
      v-loading="table.loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      @selection-change="handleSelectionChange" 
      :row-key="row => row.id" 
      class="mt20" 
      :max-height="500">
      <el-table-column
        type="selection"
        :reserve-selection="isSelShow"
        width="55">
      </el-table-column>
      <el-table-column property="no" label="名称编码" width="180">
        <template slot-scope="{row}">
          <div>{{row.good_name}}</div>
          <div v-if="!!row.norm_name" style="color:#999;">标名：{{row.norm_name}}</div>
          <div>{{row.code}}</div>
        </template>
      </el-table-column>
      <el-table-column property="good_img" label="图片" width="70">
        <template slot-scope="{row}">
          <el-image
            v-if="!!row.image"
            style="width: 40px; height: 40px"
            :src="row.image"
            fit="cover"
            :preview-src-list="[row.image]"></el-image>
          <el-image
            v-else
            style="width: 40px; height: 40px"
            :src="require('@/assets/img/logo-icon.jpeg')"
            fit="cover"
            :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
        </template>
      </el-table-column>
      <el-table-column property="unit_name" label="单位" width="60"></el-table-column>
      <el-table-column property="name" label="规格"></el-table-column>
      <el-table-column property="order_cate_name" label="下单分类" width="120px">
        <template slot-scope="{row}">
          <span>{{row.order_cate_name}}</span>
          <span v-if="!!row.order_two_name"> / {{row.order_two_name}}</span>
        </template>
      </el-table-column>
      <el-table-column property="sys_quantity" label="系统库存"></el-table-column>
      <el-table-column property="quantity" label="实际库存" width="100px">
        <template slot-scope="{row}">
          <el-input v-model="row.quantity" placeholder="请输入" size="small" @input="numREG_EXP(row)"></el-input>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageWrap" v-if="table.total > 10">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="table.params.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="table.params.count"
        layout="total, sizes, prev, pager, next"
        :total="table.total">
      </el-pagination>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button  size="small" @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="confirm" size="small" >确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getStorage } from '@/storage'
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'BatchGoodsAdd',
    props:['merchant_id'],
    mixins: [mixinTable],
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: true,
        multipleSelection: [],
        table: {
          params:{
            page:1,
            count:10,
            status: '', // 1：在架商品,2下架商品
            bind_id: '', // 供应商/采购员
            cate_id: '', // 商品分类
            good_name: '', // 商品名称
          },
          total: 0,
          data:[],
        },
        isSelShow: true,
        orderCateOpt: [], // 下单分类
        supplierArr: [], // 供应商/采购员
        cateIndex: 0,//下单分类索引
        cateIndex2: 0,//二级下单分类索引
      }
    },
    created() {
      this.getTable();
      this.getOrderCate(); // 下单分类
      this.getSupplierSel(); // 供应商/采购员
    },
    methods: {
      // 获取商品列表
      getTable() {
        let _params = {
          ...this.table.params
        }
        // _params.merchant_id = this.merchant_id || '';
        this.$http.get("/admin/goods/specList",{params: _params}).then((res) => {
          if (res.code === 1) {
            this.table.loading = false;
            this.table.data = res.data.list.map(v => {
              return {
                quantity:1,
                ...v
              }
            });
            this.table.total = res.data.total;
          }
        })
      },
      // 重置查询条件
      resetSel() {
        this.table.params = {
          page: 1,
          count: 10,
          status: '', // 1：在架商品,2下架商品
          bind_id: '', // 采购类型
          cate_id: '', // 商品分类
          good_name: '', // 商品名称
        }
        this.getTable();
      },
      // 下单分类
      getOrderCate() {
        this.$http.get('/admin/mini_cate/list', {params:{page:1,count:1000}}).then(res => {
          if(res.code === 1) {
            let str = {
              id: '',
              name: '全部',
              child:[]
            }
            this.orderCateOpt = res.data.list.map(v => {
              return {
                ...v,
                child: v.child.length > 0 ? [{id:'',name:'全部'}, ...v.child] : v.child
              }
            });
            this.orderCateOpt.unshift(str);
          }
        })
      },
      // 下单分类查询
      handleCate(index,v) {
        this.cateIndex = index;
        this.table.params.order_cate_id = v.id;
        this.table.params.order_cate_tow = '';
        this.getTable();
      },
      // 二级下单分类查询
      handleCate2(index2,j) {
        this.cateIndex2 = index2;
        this.table.params.order_cate_tow = j.id;
        this.getTable();
      },
      // 批量选择
      handleSelectionChange(val) {
        this.multipleSelection = val;
        console.log(this.multipleSelection)
      },
      numREG_EXP(row) {
        const reg = /^[\d]+$/;
        // 任何单位的数量都可以输入2位小数
        if (row.quantity && !reg.test(row.quantity)) {
          row.quantity = row.quantity=row.quantity.match(/\d+(\.\d{0,2})?/) ? row.quantity.match(/\d+(\.\d{0,2})?/)[0] : '';
        }
      },
      // 提交
      confirm() {
        if(this.multipleSelection.length > 0) {
          this.$emit('sendgoodslist',this.multipleSelection.map(v => {
            return {
              ...v,
              quantity: Number(v.quantity)
            }
          }))
        } else {
          this.$alert('请选择需要添加的商品', '', {
            confirmButtonText: '确定',
            type: 'error',
            callback: action => {
             
            }
          });
        }
      },
      // 取消 
      handleClose() {
        this.$parent.isShowDialog = false;
      }
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
}
.footerWrap {
  text-align: center;
  padding: 10px;
   box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.pageWrap {
  margin-top: 10px;
  text-align: center;
}
.cate-item {
  height: 26px;
  line-height: 26px;
  border-radius: 6px;
  padding: 0 10px;
  border: solid 1px #eee;
  margin: 0 5px;
  cursor: pointer;
  display: inline-block;
  margin-top: 5px;
}
.active {
  background: #eaf7f0;
  border: solid 1px #2cb167;
  color: #2cb167;
}
</style>
